export default [
  {
    header: 'Quản trị thông tin',
    resource: 'admin',
    action: 'read',
  },
  {
    title: 'Danh sách tài khoản',
    route: 'admin-user-list',
    icon: 'UserIcon',
    resource: 'admin-user',
    action: 'read',
  },

];
