export default [
  {
    header: 'Người dùng & Quyền',
    resource: 'permission',
    action: 'read',
  },
  {
    title: 'Loại tài khoản',
    route: 'role-list',
    icon: 'PocketIcon',
    resource: 'role',
    action: 'read',
  },
  {
    title: 'Người dùng',
    route: 'manager-list',
    icon: 'UsersIcon',
    resource: 'manager',
    action: 'read',
  },
];
